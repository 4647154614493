<template>
  <div class="activation">
    <p class="title">直接激活已购买成功的课程</p>
    <p class="desc">激活成功后，新购买的课程将自动激活</p>

    <van-field center class="mobile-input" v-model="mobile" type="tel" placeholder="输入购买时手机号"/>
    <van-button class="active-btn" @click="handleActive">激活</van-button>
    <van-button class="cancel-btn" @click="handleCancel">取消</van-button>

    <div class="footer">
      <div class="tool" @click="handleTool('subscription')">
        <img src="../../assets/img/token/project.png"/>
        <p>订阅课程</p>
      </div>
      <div class="tool" @click="handleTool('service')">
        <img src="../../assets/img/token/wechat.png"/>
        <p>联系客服</p>
      </div>
      <a class="tool" href="https://free.wq-ky.com">
        <img src="../../assets/img/free.png"/>
        <p>免费试用</p>
      </a>
    </div>
    <van-overlay :show="qrcodeVisible" @click="qrcodeVisible = false">
      <div class="content">
        <div class="block" @click.stop>
          <p class="wechat-desc">关注<span>“无穹解题”</span>内有客服售后、交流分享群</p>
          <img class="qrcode" src="../../assets/img/activity/qrcode.png"/>

          <p class="alert">长按识别二维码</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script type="application/javascript">
import { Toast, Dialog } from 'vant';

export default {
  data () {
    return {
      mobile: '',
      qrcodeVisible: false
    }
  },
  methods: {
    async handleActive () {
      if (!this.mobile) {
        Toast('请输入手机号')
        return;
      }
      const phoneRegexp = /^(1[3-9][0-9])\d{8}$/
      if (!phoneRegexp.test(this.mobile)) {
        Toast('请输入正确的手机号');
        return
      }

      try {
        const ret = await this.$api.register({
          param: {
            openid: localStorage.openid,
            username: this.mobile
          },
          method: 'post'
        });
        if (ret.hasOwnProperty('wq_access_token')) {
          localStorage.access_token = ret.wq_access_token;
          this.$router.push('/');
        } else {
          Dialog.alert({
            title: '没有找到手机号',
            message: '请使用购买时使用的手机号激活\n' +
                '拼团用户需要待拼团成功后激活\n' +
                '如有疑问请咨询客服微信',
          }).then(() => {});
        }
      } catch (err) {
        console.error(err)
      }
    },

    handleCancel () {
      this.$router.push('/');
    },

    handleTool (type) {
      if (type === 'subscription') {
        wx.miniProgram.navigateTo({url: '/pages/buyPage/index'})
        return
      }
      this.qrcodeVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.activation {
  .title {
    height: 24px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2A3155;
    line-height: 24px;
    margin: 32px 0 4px;
  }

  .desc {
    height: 19px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #9498AA;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .mobile-input {
    width: 327px;
    border-radius: 22px;
    border: 1px solid #E6E8EF;
    margin: 0 auto 16px;

    /deep/ .van-field__control {
      text-align: center;
    }

    /deep/ .van-field__control::placeholder {
      text-align: center;
    }
  }

  .active-btn {
    width: 327px;
    height: 48px;
    line-height: 48px;
    background: #F9C200;
    box-shadow: 0px 2px 4px 0px rgba(42, 49, 85, 0.1);
    border-radius: 24px;

    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
  }

  .cancel-btn {
    width: 327px;
    height: 48px;
    line-height: 48px;
    background: transparent;
    border: none;
    color: #999;
  }

  .wechat-desc {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2A3155;

    > span {
      color: #415FD5;
      font-weight: bold;
    }
  }

  .qrcode {
    width: 187px;
    height: 187px;
    margin: 24px auto;
  }

  .powerBy {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    > img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    > p {
      height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #2A3155;
      line-height: 21px;
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 52px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;

    .tool {
      > img {
        width: 32px;
        height: 32px;
      }

      > p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9498AA;
        line-height: 17px;
        margin-top: 8px;
      }
    }

    .tool:first-child {
      // margin-right: 52px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      background: white;
      padding: 24px;
      border-radius: 4px;

      .alert {
        color: #9498AA;
      }
    }
  }
}
</style>
